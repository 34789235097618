import React from "react";
import { Auth } from 'aws-amplify';
import QRCode from 'qrcode.react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    useLocation
} from "react-router-dom";
import { Container, Form, Col, Row, Button } from 'react-bootstrap';
import 'cross-fetch/polyfill';
import TermsAndCondition from "./terms";
import Loading from "../components/loading";


const initialFormState = {
    firstName: '', lastName: '', totpCode: '', verificationCode: ''
}



const App = () => {

    const [user, setUser] = React.useState();
    const [qcode, setQrCode] = React.useState('');
    const [qcodevalue, setQrcodeValue] = React.useState('');
    const [error, setError] = React.useState(false);
    const [notify, setNotify] = React.useState(false);
    const [enrolled, setEnrolled] = React.useState(true)
    const [userAttributes, setUserAttributes] = React.useState();
    let history = useHistory();
    const [formState, setFormState] = React.useState(initialFormState);

    const [modalShow, setModalShow] = React.useState(false);

    const [logState, setLogState] = React.useState();
    const [emailVerified, setEmailVerified] = React.useState(false);
    const [emailCodeSent, setEmailCodeSent] = React.useState(0);


    function onChange(event) {
        event.persist();
        setFormState(() => ({ ...formState, [event.target.name]: event.target.value }))
    }


    React.useEffect(() => {

        async function getUserSession() {
            try {
                await Auth.currentSession();

            } catch (error) {
                Auth.federatedSignIn();

            }


        }

        async function verifyAttribute() {


            let authData = await Auth.currentAuthenticatedUser();

            let emailSentCount = Number(window.sessionStorage.getItem("EmailSendCount"))

            if (authData.attributes.email_verified !== true) {

                setEmailCodeSent(emailCodeSent + 1);


                if (emailSentCount < 1) {
                    window.sessionStorage.setItem("EmailSendCount", emailCodeSent + 1);
                    Auth.verifyUserAttribute(authData, 'email').then((res) => {

                    }).catch((error) => {
                        console.error(error)
                    })
                }

            }
            else {
                window.sessionStorage.setItem("EmailVerified", true);
                setEmailVerified(true);

            }

        }

        async function authStateChange() {

            let authData = await Auth.currentAuthenticatedUser();
            setUser(authData);
            Auth.getPreferredMFA(authData, { bypassCache: true }).then((data) => {
                if (data === "SOFTWARE_TOKEN_MFA") {
                    setEnrolled(true);
                    Auth.signOut();

                }
                else {

                    // Set Up TOTp Code

                    setEnrolled(false);

                    Auth.setupTOTP(authData).then((code) => {
                        const qvalue = 'otpauth://totp/AWSCognito:' + authData.username + '?secret=' + code + '&issuer=AmazonLegal'
                        setQrCode(qvalue);
                        setQrcodeValue(code);

                    });

                    //Fetch User Properties

                    Auth.userAttributes(authData).then((attributes) => {
                        setUserAttributes(attributes);

                    })
                }


            }).catch(e => {
                if (e.name === "UserNotFoundException") {
                    Auth.federatedSignIn();
                }
            })

        }

        getUserSession();
        verifyAttribute();
        authStateChange();

    }, []);



    const Terms = () => {

        return (<a href='#' id='inlineFormCheck' >terms and condition</a>);

    }




    const updateUser = (userobj) => {
        Auth.updateUserAttributes(userobj, {
            "given_name": formState.firstName, "family_name": formState.lastName
        }).then(data => {
            setEnrolled(true);
            Auth.signOut();
        })

    }

    const verifyEmailSendCode = (event) => {
        event.preventDefault();
        Auth.verifyUserAttribute(user, 'email').then((res) => {

            setNotify(true);

        }).catch((error) => {

            setNotify(true);

        })
    }

    const verifyEmail = (event) => {
        event.preventDefault();
        Auth.verifyUserAttributeSubmit(user, 'email', formState.verificationCode).then((res) => {


            window.sessionStorage.setItem("EmailVerified", true);
            setEmailVerified(true);
            setError(false);
            setNotify(false);


        }).catch((error) => {
            setError(true);
            setNotify(false);
            console.error(error) // hitting error here 
        })
    }

    const setPreferredMFA = (event) => {

        event.preventDefault();
        const cognitoUser = user;


        cognitoUser.verifySoftwareToken(formState.totpCode, 'authenticator app', {
            onSuccess: function (result) {
                const totpMfaSettings = {
                    PreferredMfa: true,
                    Enabled: true
                }
                cognitoUser.setUserMfaPreference(null, totpMfaSettings, function (error, result) {
                    if (error) {
                        setError(true);

                    }
                    else {

                        updateUser(user);
                    }

                });

            }
            , onFailure: function (authFailData) {
                setError(true);
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });

            }
        });


    }

    if (user && window.sessionStorage.getItem("EmailVerified") !== true && emailVerified !== true) {


        return (<div className="App">


            <Container style={{ backgroundColor: "white", maxWidth: "350px", padding: "0px", boxShadow: "0 5px 15px rgba(0,0,0,.5)", color: "#3B3B3B" }} className="mt-5">
                <div style={{ backgroundColor: "#232F3E", minHeight: "62px", padding: "10px 0px 10px 0px" }}> <center><img className="logo-customizable" src="/img/Logo.jpg" alt="Amazon Legal Logo" /></center></div>

                <Form onSubmit={verifyEmail} style={{ backgroundColor: "white", paddingLeft: "15px", paddingRight: "15px", fontFamily: "Arial", fontSize: "14px", color: "#3B3B3B", display: "block" }} className="m-15" >

                    <Form.Row>
                        <Form.Group as={Col} controlId="formBasicText">
                            <p className="mt-3" style={{ font: "Arial", color: "#3B3B3B" }}>We have sent a code to your email. Enter it below to confirm your account </p>
                            {
                                error ? <Form.Label className="errorMessage-customizable">
                                    Invalid verification code provided, please try again.
                                </Form.Label> : <div></div>
                            }
                            {
                                notify ? <Form.Label className="infoMessage-customizable">
                                    Codes may take several minutes to arrive. If you have stopped receiving them, you have exceeded the daily limit. Please wait and try again tomorrow.
                                </Form.Label> : <div></div>
                            }
                            <Form.Control type="number" required name="verificationCode" placeholder="Verification Code" onChange={onChange} />
                        </Form.Group>
                    </Form.Row>

                    <Button className="amzn-btn mt-3" type="submit" >
                        Confirm Account
                    </Button>

                    <Form.Row className="mt-3">
                        <Form.Group>
                            <label className="ml-2" style={{ font: "Arial", fontSize: "14px", color: "#3B3B3B" }} >Didn't receive a code?</label>
                            <a href='#' className="ml-1" onClick={verifyEmailSendCode} style={{ font: "Arial", fontSize: "14px", color: "#337AB7" }} >Resend the code</a>
                        </Form.Group>
                    </Form.Row>



                </Form>

            </Container>
        </div >);


    }
    else if (user && window.sessionStorage.getItem("EmailVerified") && enrolled !== true) {
        return (

            <div className="App">

                <Container style={{ backgroundColor: "white", maxWidth: "500px", padding: "0px", boxShadow: "0 5px 15px rgba(0,0,0,.5)" }} className="mt-5 mb-2">
                    <div style={{ backgroundColor: "#232F3E", maxWidth: "100%", height: "70px" }}> <center><img className="mt-3" style={{ maxWidth: "40%" }} src="/img/Logo.jpg" alt="Amazon Legal Logo" /></center></div>
                    <div className="page-header text-center mt-2 mb-2"><h4 className="contentheader">Set up Amazon Legal Passport Account</h4></div>

                    <Form onSubmit={setPreferredMFA} style={{ backgroundColor: "white", paddingLeft: "15px", paddingRight: "15px", fontFamily: "Arial", fontSize: "14px", color: "#3B3B3B", display: "block" }} className="m-15" >
                        {
                            error ? <Form.Label className="errorMessage-customizable">
                                Invalid code
                            </Form.Label> : <div></div>
                        }
                        <Form.Row>

                            <Form.Label>
                                Email
                            </Form.Label>
                            <Form.Control disabled defaultValue={user.attributes.email} />
                            {/* </Form.Group> */}
                        </Form.Row>
                        <Form.Row>
                            {/* <Form.Group as={Col} controlId="formBasicText"> */}
                            <Form.Label>
                                First Name
                            </Form.Label>
                            {/* <Form.Label className="ml-1 error-lbl">*</Form.Label> */}
                            <Form.Control text required name="firstName" placeholder="First Name" onChange={onChange} />
                            {/* </Form.Group> */}
                        </Form.Row>
                        <Form.Row>
                            {/* <Form.Group as={Col} controlId="formBasicText"> */}

                            <Form.Label>
                                Last Name
                            </Form.Label>
                            {/* <Form.Label className="ml-1 error-lbl">*</Form.Label> */}

                            <Form.Control text required name="lastName" placeholder="Last Name" onChange={onChange} />

                            {/* </Form.Group> */}
                        </Form.Row>
                        <div class="page-header text-center mt-2"><h4 className="contentheader">Set up Two-Factor Authentication</h4></div>
                        {/* <Form.Row class="text-center"> */}
                        {/* <Form.Group as={Col} controlId="formBasicText"> */}
                        {/* <p class="text-center">Follow these steps to enable two-factor Authentication on your account</p> */}

                        {/* </Form.Group> */}
                        {/* </Form.Row> */}
                        <p class="text-white bg-secondary pl-2"> 1. Download and install an authenticator app</p>
                        <p> Please use a Time-based One-Time Password (TOTP) compatible authenticator app such as Google Authenticator, Microsoft Authenticator, Authy, or 1Password.</p>
                        <p class="text-white bg-secondary pl-2"> 2. Open the authenticator app to scan the QR Code</p>
                        <Form.Row class="text-center">

                            <div>
                                <QRCode value={qcode} />
                            </div>

                        </Form.Row>
                        <p>If you are unable to scan the QR Code, enter the information below into your authenticator app</p>
                        <p className="text-center container-fluid" style={{ borderStyle: "dashed solid", wordBreak: "break-all" }}>{qcodevalue} </p>
                        <p class="text-white bg-secondary pl-2"> 3. Enter your validation code from the authenticator app</p>
                        <Form.Row>
                            <Form.Group controlId="formBasicText">
                                <div className="inline">
                                    <Form.Control name="totpCode" className="ml-2" number required placeholder="Enter the 6-digit code" onChange={onChange} />

                                </div>
                            </Form.Group>
                            {/* <Form.Label className="formLabel ml-3 error-lbl">*</Form.Label> */}


                        </Form.Row>
                        <Form.Row>
                            <Form.Group>

                                {/* <input required type="checkbox" className="chkBoxAgree ml-2" ></input> */}

                                <label className="ml-2">
                                    By creating an account, you agree to these
                                </label>
                                <a href='#' className="ml-1" onClick={() => setModalShow(true)}  >Terms of Use</a>
                            </Form.Group>
                        </Form.Row>
                        <Button className="amzn-btn" type="submit" >
                            Submit
                        </Button>
                    </Form>

                    <TermsAndCondition
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                    />
                </Container>
            </div >
        );
    }
    else {
        return (<Loading />);
    }
}


export default App;
