import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Card } from 'react-bootstrap';


const EnrollmentConfirm = () => {
    return (
        <div>
            <Container className="mt-5" style={{ backgroundColor: "white", maxWidth: "350px", padding: "0px", boxShadow: "0 5px 15px rgba(0,0,0,.5)" }}>
                <div style={{ backgroundColor: "#232F3E", minHeight: "62px", padding: "10px 0px 10px 0px" }}> <center><img className="logo-customizable" src="/img/Logo.jpg" alt="Amazon Legal Logo" /></center></div>
                <Card>
                    <Card.Body>
                        <Card.Title>Account Activated</Card.Title>
                        <Card.Text className="cardMessageBody">
                            Your Amazon Legal Passport account has been successfully activated. You can close this browser window.
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );

}

export default EnrollmentConfirm;
