import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import Portal from "./modules/portal"
import Logout from "./modules/logout"
import LogoutSuccess from "./modules/logoutsuccess"
import EnrollmentConfirm from "./modules/enrollconfirm"
import { PATH_LOGOUT, PATH_ENROLL_COMPLETED, PATH_LOGOUT_SUCCESS, PATH_ROOT } from "./config/path"


const App = () => {
    return (<Router><Switch>
        <Route path={PATH_LOGOUT} component={Logout} />
        <Route path={PATH_ENROLL_COMPLETED} component={EnrollmentConfirm} />
        <Route path={PATH_LOGOUT_SUCCESS} component={LogoutSuccess} />
        <Route path={PATH_ROOT} component={Portal} />
    </Switch></Router>);
}

export default App;