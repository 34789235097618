import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Container } from 'react-bootstrap';


const LogoutSuccess = () => {
    return (
        <div>

            <Container className="mt-5" style={{ backgroundColor: "white", maxWidth: "380px", padding: "0px", boxShadow: "0 5px 15px rgba(0,0,0,.5)" }}>
                <div style={{ backgroundColor: "#232F3E", minHeight: "62px", padding: "10px 0px 10px 0px" }}> <center><img className="logo-customizable" src="/img/Logo.jpg" alt="Amazon Legal Logo" /></center></div>
                <Card>
                    <Card.Body >
                        <Card.Title>Logout Successful</Card.Title>
                        <Card.Text className="cardMessageBody">
                            Please close the browser to complete sign out.
                        </Card.Text>

                    </Card.Body>
                </Card>
            </Container>
        </div>
    );

}

export default LogoutSuccess;
