import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Spinner } from 'react-bootstrap';


const Loading = () => {
    return (
        <div className="mt-5 d-flex justify-content-center text-center">
            <Spinner animation="grow" variant="secondary" className="spinYellow" />
            <Spinner animation="grow" variant="secondary" className="spinBlue" />
            <Spinner animation="grow" variant="secondary" className="spinYellow" />
            <Spinner animation="grow" variant="secondary" className="spinBlue" />
            <Spinner animation="grow" variant="secondary" className="spinYellow" />
            <Spinner animation="grow" variant="secondary" className="spinBlue" />
        </div>
    );
}
export default Loading;
