
import { getEnvironment } from "./environment";

const getConfig = stage => {
    console.log("Stage");
    console.log(stage);
    if (stage === "prod-pdx") {
        return {
            "Auth": {
                "region": "us-west-2",
                "userPoolId": "us-west-2_hzsf6xet8",
                "userPoolWebClientId": "7sqisnq01knv1e1sqd0s6tfk4f",
                "mandatorySignIn": false,
                "cookieStorage": {
                    "domain": "portal.us-west-2.prod.passport.legal.amazon.com",
                    "path": "/",
                    "expires": 365,
                    "secure": true
                },
                "redirectSignIn": "https://portal.us-west-2.prod.passport.legal.amazon.com/",
                "redirectSignOut": "https://portal.us-west-2.prod.passport.legal.amazon.com/enrollconfirm"
            }, oauth: {
                "domain": "auth.us-west-2.prod.passport.legal.amazon.com",
                "scope": [
                    "email",
                    "profile",
                    "openid",
                    "aws.cognito.signin.user.admin"
                ],
                "redirectSignIn": "https://portal.us-west-2.prod.passport.legal.amazon.com/",
                "redirectSignOut": "https://portal.us-west-2.prod.passport.legal.amazon.com/enrollconfirm",
                "responseType": "token"
            }


        }
    }
    else if (stage === "gamma-pdx") {

        return {
            "Auth": {
                "region": "us-west-2",
                "userPoolId": "us-west-2_GGxJfemQX",
                "userPoolWebClientId": "6ie69re090lnpldg5tpv4m6sem",
                "mandatorySignIn": false,
                "cookieStorage": {
                    "domain": "portal.us-west-2.gamma.passport.legal.amazon.com",
                    "path": "/",
                    "expires": 365,
                    "secure": true
                },
                "redirectSignIn": "https://portal.us-west-2.gamma.passport.legal.amazon.com/",
                "redirectSignOut": "https://portal.us-west-2.gamma.passport.legal.amazon.com/enrollconfirm"
            }, oauth: {
                "domain": "auth.us-west-2.gamma.passport.legal.amazon.com",
                "scope": [
                    "email",
                    "profile",
                    "openid",
                    "aws.cognito.signin.user.admin"
                ],
                "redirectSignIn": "https://portal.us-west-2.gamma.passport.legal.amazon.com/",
                "redirectSignOut": "https://portal.us-west-2.gamma.passport.legal.amazon.com/enrollconfirm",
                "responseType": "token"
            }


        }

    }
    else if (stage === "beta-pdx") {

        return {
            "Auth": {
                "region": "us-west-2",
                "userPoolId": "us-west-2_5SNQgQuj3",
                "userPoolWebClientId": "3okc2ddrrg6kdbcptm84th98h",
                "mandatorySignIn": false,
                "cookieStorage": {
                    "domain": "portal.us-west-2.beta.passport.legal.amazon.com",
                    "path": "/",
                    "expires": 365,
                    "secure": true
                },
                "redirectSignIn": "https://portal.us-west-2.beta.passport.legal.amazon.com/",
                "redirectSignOut": "https://portal.us-west-2.beta.passport.legal.amazon.com/enrollconfirm"
            }, oauth: {
                "domain": "auth.us-west-2.beta.passport.legal.amazon.com",
                "scope": [
                    "email",
                    "profile",
                    "openid",
                    "aws.cognito.signin.user.admin"
                ],
                "redirectSignIn": "https://portal.us-west-2.beta.passport.legal.amazon.com/",
                "redirectSignOut": "https://portal.us-west-2.beta.passport.legal.amazon.com/enrollconfirm",
                "responseType": "token"
            }


        }

    }
    else if (stage === "alpha-pdx") {

        return {
            "Auth": {
                "region": "us-west-2",
                "userPoolId": "us-west-2_12AFuUVzB",
                "userPoolWebClientId": "2dd5m23h3k2rse9u1i484aqlol",
                "mandatorySignIn": false,
                "cookieStorage": {
                    "domain": "portal.us-west-2.alpha.passport.legal.amazon.com",
                    "path": "/",
                    "expires": 365,
                    "secure": true
                },
                "redirectSignIn": "https://portal.us-west-2.alpha.passport.legal.amazon.com/",
                "redirectSignOut": "https://portal.us-west-2.alpha.passport.legal.amazon.com/enrollconfirm"
            }, oauth: {
                "domain": "auth.us-west-2.alpha.passport.legal.amazon.com",
                "scope": [
                    "email",
                    "profile",
                    "openid",
                    "aws.cognito.signin.user.admin"
                ],
                "redirectSignIn": "https://portal.us-west-2.alpha.passport.legal.amazon.com/",
                "redirectSignOut": "https://portal.us-west-2.alpha.passport.legal.amazon.com/enrollconfirm",
                "responseType": "token"
            }


        }

    }
    else {

        return {
            "Auth": {
                "region": "us-west-2",
                "userPoolId": "us-west-2_12AFuUVzB",
                "userPoolWebClientId": "4h6vqf62h52q5v468ibqngeg24",
                "mandatorySignIn": false,
                "cookieStorage": {
                    "domain": "localhost",
                    "path": "/",
                    "expires": 365,
                    "secure": true
                },
                "redirectSignIn": "http://localhost:59310/",
                "redirectSignOut": "http://localhost:59310/enrollconfirm"
            }, oauth: {
                "domain": "auth.us-west-2.alpha.passport.legal.amazon.com",
                "scope": [
                    "email",
                    "profile",
                    "openid",
                    "aws.cognito.signin.user.admin"
                ],
                "redirectSignIn": "http://localhost:59310/",
                "redirectSignOut": "http://localhost:59310/enrollconfirm",
                "responseType": "token"
            }


        }

    }
}

const awsconfig = getConfig(getEnvironment());

export default awsconfig;
