import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Loading from '../components/loading'
import AppConfig from '../config/app-config'

export class Logout extends Component {
    constructor(props) {
        super();
        this.state = { ...props };
    }
    componentWillMount() {

        const logoutUrl = AppConfig.logout_url
        const clienttId = AppConfig.client_id
        const redirectUrl = AppConfig.redirect_url
        const signOutUrl = logoutUrl + "?client_id=" + clienttId + "&logout_uri=" + redirectUrl
        window.location = signOutUrl;
    }
    render() {
        return (
            <Loading />
        );
    }
}

export default Logout;
