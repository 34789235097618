
import { getEnvironment } from "./environment";

const getConfig = stage => {
    if (stage === "prod-pdx") {
        return {
            "logout_url": "https://auth.us-west-2.prod.passport.legal.amazon.com/logout",
            "redirect_url": "https://portal.us-west-2.prod.passport.legal.amazon.com/logoutsuccess",
            "client_id": "7k1nl95ebgfv91kf5399tnod31"
        }
    }
    else if (stage === "gamma-pdx") {
        return {
            "logout_url": "https://auth.us-west-2.gamma.passport.legal.amazon.com/logout",
            "redirect_url": "https://portal.us-west-2.gamma.passport.legal.amazon.com/logoutsuccess",
            "client_id": "23sg9rieoi7tqs100qu7vju736"
        }
    }
    else if (stage === "beta-pdx") {
        return {
            "logout_url": "https://auth.us-west-2.beta.passport.legal.amazon.com/logout",
            "redirect_url": "https://portal.us-west-2.beta.passport.legal.amazon.com/logoutsuccess",
            "client_id": "4uivq660tl2v1lahbalso5r8nq"
        }

    }
    else if (stage === "alpha-pdx") {
        return {
            "logout_url": "https://auth.us-west-2.alpha.passport.legal.amazon.com/logout",
            "redirect_url": "https://portal.us-west-2.alpha.passport.legal.amazon.com/logoutsuccess",
            "client_id": "1bq0g7ds5bun1scufr3end12e2"
        }

    }
    else {
        return {
            "logout_url": "https://auth.us-west-2.alpha.passport.legal.amazon.com/logout",
            "redirect_url": "http://localhost:59310/logoutsuccess",
            "client_id": "8mhuhgseef3tj42jv2409iiis"
        }

    }
}
const appconfig = getConfig(getEnvironment());

export default appconfig;
