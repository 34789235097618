import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal } from 'react-bootstrap';


const TermsAndCondition = (props) => {
    return (

        <Modal
            {...props}
            size="lg sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Terms of Use
          </Modal.Title>
            </Modal.Header>
            <Modal.Body className="cardMessageBody">
                <h5>Last updated March 11, 2021</h5>
                <p>
                    Only persons with authorization from Amazon may access and use this application or any downstream application accessed through this application. Any other access or use is prohibited. You will maintain confidentiality of data accessed through these applications and only use such data for authorized purposes. Amazon may monitor all access to and from these applications and collect data related to your usage, which may include use of cookies and collection of personal data such as name, email, IP address, and session id. Your use of the applications authorizes such activity. These terms supplement but do not override other applicable terms you or your employer may have in place with Amazon.
                    Amazon may update these terms at its sole discretion by posting revised terms at https://passport.legal.amazon.com.
                    Your continued use of the applications after the effective date of the revised terms constitutes your acceptance of the revised terms. If you need to contact Amazon regarding your use of this application please contact the Amazon employee who arranged your access to this application.
          </p>
            </Modal.Body>
            <Modal.Footer>
                <Button className="amzn-btn" onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );

}

export default TermsAndCondition;

