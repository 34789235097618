const currentHost = window.location.hostname;

const getEnvironment = () => {
    if (currentHost === "portal.us-west-2.prod.passport.legal.amazon.com")
        return "prod-pdx";
    else if (currentHost === "portal.us-west-2.gamma.passport.legal.amazon.com")
        return "gamma-pdx";
    else if (currentHost === "portal.us-west-2.beta.passport.legal.amazon.com")
        return "beta-pdx";
    else if (currentHost === "portal.us-west-2.alpha.passport.legal.amazon.com")
        return "alpha-pdx";
    else
        return "dev";
};

export { getEnvironment };