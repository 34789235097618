/**
 * The root of the react application. This is where it all comes together -
 * follow the imports of this file to find everything that is included in the
 * application.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react"
import ReactDOM from "react-dom"
import "@amzn/meridian-tokens/base/font/amazon-ember.css"
import "@amzn/meridian-tokens/base/font/bookerly.css"
import App from "./App"
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from "./config/aws-config"
import './styles/index.css'


// Amplify.configure({
//     "Auth": {
//         "region": "us-west-2",
//         "userPoolId": "us-west-2_12AFuUVzB",
//         "userPoolWebClientId": "8mhuhgseef3tj42jv2409iiis",
//         "identityPoolId": "us-west-2:7d302aa2-0e46-4e2b-ad76-01fe837315f5",
//         "mandatorySignIn": false,
//         "cookieStorage": {
//             "domain": "localhost",
//             "path": "/",
//             "expires": 365,
//             "secure": true
//         },
//         "redirectSignIn": "http://localhost:59310/",
//         "redirectSignOut": "http://localhost:59310/logoutsuccess"
//     },
//     "API": {
//         "endpoints": [
//             {
//                 "name": "demo",
//                 "endpoint": "http://localhost:59310/"
//             }
//         ]
//     }
// })
// Auth.configure({
//     oauth: {
//         "domain": "auth.us-west-2.alpha.passport.legal.amazon.com",
//         "scope": [
//             "phone",
//             "email",
//             "profile",
//             "openid",
//             "aws.cognito.signin.user.admin"
//         ],
//         "redirectSignIn": "http://localhost:59310/",
//         "redirectSignOut": "http://localhost:59310/logout",
//         "responseType": "token"
//     }
// })

//Amplify.Logger.LOG_LEVEL = 'DEBUG';

Amplify.configure(awsconfig);
// //set up Auth module
Auth.configure(awsconfig)

// const currentConfig = Auth.configure();

// console.log(currentConfig)


ReactDOM.render(<App />, document.getElementById("root"))